<template>
    <Content
      :class="['content', {'workflow-in-iframe': !isTop}]"
      style="height:100%"
    />
</template>

<script>
import Vue from 'vue'
import { mapActions } from 'vuex'
import Content from './Content'

export default {
  components: {
    Content
  },
  data () {
    return {
      showMenu: false,
      isTop: window === window.top
    }
  },
  computed: {
    inIframe () {
      return window !== window.top
    }
  },
  created () {
    this.getLanguages()
    Vue.prototype.$createElement = this.$createElement
  },
  mounted () {
    this.$nextTick(() => {
      if (window.laterJs && window.laterJs.length > 0) {
          window.loadJs(window.laterJs)
          window.laterJs = null
      }
    })
  },
  methods: {
    ...mapActions(['getLanguages'])
  }
}
</script>

<style lang="stylus" scoped>
//.workflow-in-iframe
//  position fixed
//  z-index 100
//  top 0
//  right 0
//  bottom 0
//  left 0
//  //background-color  #f5f5f5
//  background-color white
</style>
<style lang="stylus">
.el-message-box
  max-width 100%
</style>
