import { render, staticRenderFns } from "./PerformanceMeasure.vue?vue&type=template&id=7edf3e84&"
import script from "./PerformanceMeasure.vue?vue&type=script&lang=js&"
export * from "./PerformanceMeasure.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../modules/kuainiu/oa-app-workflow/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports