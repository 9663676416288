export const evaluation = (stack, variateValues) => {
  const valueStack = []
  const operatorStack = []
  stack.forEach(v => {
    if (v.type === 'variate') {
      debugger
      valueStack.push(variateValues[v.value])
    } else if (v.type === 'string' || v.type === 'number' || v.type === 'boolean' || v.type === 'object' || v.type === 'undefined' || v.type === 'array') {
      valueStack.push(v.value)
    } else {
      if (operatorStack.length === 0) {
        operatorStack.push(v.value)
      } else {
        const top = operatorStack[operatorStack.length - 1]
        if (priority(top) < priority(v.value) || top === '(') {
          operatorStack.push(v.value)
        } else {
          if (top === ')') {
            operatorStack.pop()
            const op = operatorStack.pop()
            const b = valueStack.pop()
            const a = valueStack.pop()
            const curr = onceEval(op, a, b)
            valueStack.push(curr)
            operatorStack.pop()
          } else {
            const op = operatorStack.pop()
            const b = valueStack.pop()
            const a = valueStack.pop()

            const curr = onceEval(op, a, b)
            valueStack.push(curr)
          }
          operatorStack.push(v.value)
        }
      }
    }
  })
  let index = operatorStack.length
  while (index--) {
    const operator = operatorStack[index]
    if (operator === '(' || operator === ')') {
      operatorStack.pop()
    } else {
      const b = valueStack.pop()
      const a = valueStack.pop()
      const val = onceEval(operator, a, b)
      valueStack.push(val)
    }
  }
  return valueStack[0]
}
const priority = (operator) => {
  const obj = {
    '(': 20,
    ')': 20,
    'AND': 6,
    'OR': 5,
    '+': 13,
    '-': 13,
    '*': 14,
    '/': 14,
    '<': 11,
    '<=': 11,
    '>': 11,
    '>=': 11,
    '==': 10,
    '!=': 10
  }
  return obj[operator]
}
const onceEval = (operator, a, b) => {
  a = Array.isArray(a) ? a.join() : a
  b = Array.isArray(b) ? b.join() : b
  switch (operator) {
    case 'AND':
      return a && b
    case 'OR':
      return a || b
    case '+':
      return add(a, b)
    case '-':
      return sub(a, b)
    case '*':
      return mul(a, b)
    case '/':
      return div(a, b)
    case 'max':
      return max(a, b)
    case 'min':
      return min(a, b)
    case '|':
      return cut(a, b)
    case '<':
      return a < b
    case '<=':
      return a <= b
    case '>':
      return a > b
    case '>=':
      return a >= b
    case '==':
      return a == b
    case '!=':
      return a != b
  }
}
export const add = (a, b) => {
  // js浮点数精度处理
  if (typeof a === 'number' && typeof b === 'number' && (/\./.test(a.toString()) || /\./.test(b.toString()))) {
    const aLen = a.toString().split('.')[1] ? a.toString().split('.')[1].length : 0
    const bLen = b.toString().split('.')[1] ? b.toString().split('.')[1].length : 0
    return parseFloat((a + b).toFixed(aLen > bLen ? aLen : bLen))
  }
  return a + b
}
export const sub = (a, b) => {
  // js浮点数精度处理
  if (typeof a === 'number' && typeof b === 'number' && (/\./.test(a.toString()) || /\./.test(b.toString()))) {
    const aLen = a.toString().split('.')[1] ? a.toString().split('.')[1].length : 0
    const bLen = b.toString().split('.')[1] ? b.toString().split('.')[1].length : 0
    return parseFloat((a - b).toFixed(aLen > bLen ? aLen : bLen))
  }
  return a - b
}
export const mul = (a, b) => {
  // js浮点数精度处理
  if (typeof a === 'number' && typeof b === 'number' && (/\./.test(a.toString()) || /\./.test(b.toString()))) {
    const aLen = a.toString().split('.')[1] ? a.toString().split('.')[1].length : 0
    const bLen = b.toString().split('.')[1] ? b.toString().split('.')[1].length : 0
    const Len = (a * b).toString().split('.')[1] ? (a * b).toString().split('.')[1].length : 0
    if (!Len) {
      return a * b
    }
    if (Len > aLen + bLen) {
      return parseFloat((a * b).toFixed(aLen + bLen))
    }
    return a * b
  }

  return a * b
}
export const div = (a, b) => {
  // js浮点数精度处理
  if (typeof a === 'number' && typeof b === 'number' && (/\./.test(a.toString()) || /\./.test(b.toString()))) {
    const aLen = a.toString().split('.')[1] ? a.toString().split('.')[1].length : 0
    const bLen = b.toString().split('.')[1] ? b.toString().split('.')[1].length : 0
    const Len = aLen > bLen ? aLen : bLen
    const times = Math.pow(10, Len)
    return (a * times) / (b * times)
  }
  return a / b
}
export const max = (a) => {
  return a.sort().reverse()[0]
}

export const min = (a) => {
  return a.sort()[0]
}

export const cut = (a) => {
  return a.join(',')
}

