import Events from '@platform_resources/js/components/performance-measure/events'

export default function (Vue, axios, apiPre) {
  // 配置axios
  const instance = axios.create({
    baseURL: apiPre || '',
    // headers: {
    //   token: store.state.user.token || '',
    //   username: store.state.user.username || ''
    // }
  })
  instance.interceptors.request.use(
    function (config) {
      const requestTimestamp = Date.now()
      config.headers['X-Requested-With'] = 'XMLHttpRequest'
      config.headers['X-Requested-Timestamp'] = requestTimestamp
      config.headers['X-Requested-TraceId'] = window.traceId
      config.headers['X-Requested-Page'] = window.location.pathname
      config.headers['X-Requested-Page-Stage'] = window.stage

      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )

  // // Add a response interceptor
  instance.interceptors.response.use(
    function (response) {
      const responseTimestamp = Date.now()
      if (response.headers['x-request-spent-time']) {
        response.data.service_spend_time = Number(response.headers['x-request-spent-time']) * 1000
      }
      const payload = {
        traceId: response.config.headers['X-Requested-TraceId'],
        location: response.config.headers['X-Requested-Page'],
        pageStage: response.config.headers['X-Requested-Page-Stage'],
        apiUri: response.config.url,
        requestTimestamp: response.config.headers['X-Requested-Timestamp'],
        requestSpentTime: response.data.service_spend_time || 0,
        responseTimestamp,
      }
      Vue.EventBus.emit(Events.RequestFinished, payload)
      return response
    },
    function (error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error)
    }
  )

  Vue.http = Vue.prototype.$http = instance
}
