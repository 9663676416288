import Vue from 'vue'
import 'es6-promise/auto'
import axios from 'axios'
import i18n from './i18n'
import store from './store'
// 模块各自的路由注册
import router from './router'
import VueRx from 'vue-rx'
import Rx from 'rxjs/Rx'
// 统一组件入口
import App from './components/App'
// import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
// import 'form-making/dist/FormMaking.css'
// import FormMaking from 'form-making'
// 表单设计器
import FormMaking from './index'
// 统一注册请求库
import request from './util/request'
// 公共部分全局样式
import './styles/base.scss'

// 本地全局样式
import './styles/index.scss'

import PerformanceMeasure from '@platform_resources/js/components/performance-measure'

// 统一注册请求库
// process.env.MIX_VUE_APP_APIPRE是API请求统一前缀
request(Vue, axios, `${process.env.MIX_VUE_APP_APIPRE}`)

Vue.component('performance-measure', PerformanceMeasure.install(Vue));

Vue.use(FormMaking)

Vue.config.productionTip = false

Vue.use(window.ELEMENT, {size: 'middle', i18n: (key, value) => i18n.t(key, value)})

Vue.use(VueRx, Rx)

new Vue({
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
