const locale = window.ELEMENT.lang.zhCN
export default {
    ...locale,
    "The approval version has been upgraded, the system will refresh it for you, please check it and try to submit again.": "审批版本已更新，系统将为您刷新，请检查填写后再次尝试提交。",
    "Cancel": "取消",
    "Cancle": "取消",
    "确定": "确定",
    "提示": "提示",
    "Confirm": "确认",
    "cancel": "取消",
    "确定删除该授权？": "确定删除该授权？",
    "您确定要删除此申请吗？不可恢复": "您确定要删除此申请吗？不可恢复",
    "确定删除该分组？": "确定删除该分组？",
    "删除分组": "删除分组",
    "确定停用该流程？": "确定停用该流程？",
    "确定启用该流程？": "确定启用该流程？",
    "确定要删除该组件吗？": "确定要删除该组件吗？",
  "message.common.info": "提示",
    "message.common.code.askDecryptionAgain": "是否重新解密?",
    "message.common.code.decryptionFailed": "解密失败",
    "message.common.code.decryptionAgain": "重新解密",
    "message.common.code.cancelDecryptionAgain": "您取消了重新解密。",
    "message.common.code.inputCode": "验证码",
    "message.common.code.sentCode": "验证码已发送",
    "message.common.code.wrongFormat": "验证码错误",
    "message.common.code.cancelCode": "您取消了验证码提交。",
    "message.common.code.wrongSend": "发送验证码出错。",
    "message.common.code.wrongSubmit": "提交验证码出错。",
    "message.common.code.successSubmit": "验证码提交成功。",
    "message.common.code.reloadTry": "请刷新界面，重试。",
    "message.common.code.secondsLater": "秒后可",
    "message.common.code.sendAgain": "重新获取",
    "message.common.code.tipsContent1": "系统申请解密",
    "message.common.code.tipsContent3": "数据，请输入验证码完成授权。",
    "message.common.code.placeholder": "验证码将通过 TV 或邮件发送",
    "message.common.code.frequent": "验证码获取太频繁，请稍后重新获取",
    "message.common.code.wrongCode": "验证码无效，请重试！",
    "message.common.code.wrongTooManyTimes": "验证码输错多次，请重新获取验证码",
    "message.common.code.overdueCode": "验证码已失效，请重新获取验证码’",
    "message.common.uploadFile": "上传文件",
    "message.common.uploadPicture": "上传图片",
    "message.common.ok": "确定",
    "message.common.cancel": "取消",
    "message.common.submit": "提交",
    "message.common.saveAsDraft": "保存为草稿",
    "message.common.tips": "提示",
    "message.common.warning": "警告",
    "message.common.start": "开始",
    "message.common.end": "结束",
    "message.common.processNode": "流程节点",
    "message.common.subTask": "子任务",
    "message.common.pending": "待处理",
    "message.common.executeException": "执行异常",
    "message.common.lostEfficacy": "已失效",
    "message.common.preview": "预览",
    "message.common.download": "下载",
    "message.common.yes": "是",
    "message.common.no": "否",
    "message.common.width": "宽度",
    "message.common.height": "高度",
    "message.common.add": "添加",
    "message.common.node": "节点",
    "message.common.select": "选择",
    "message.common.selected": "已选",
    "message.common.validate.required": "必填",
    "message.common.validate.validateFaild": "验证不通过",
    "message.common.validate.wrongFormat": "格式不匹配",
    "message.common.validate.loadingData": "有数据正在加载中...，请稍后",
    "message.common.validate.noApproverSelected": "未选择审批人",
    "message.common.placeholder.select": "请选择",
    "message.common.placeholder.remark": "请填写备注",
    "message.common.placeholder.notRequired": "非必填",
    "message.common.placeholder.keyTofilter": "输入关键字进行过滤",
    "message.common.requestTips.failed": "操作失败，请重试",
    "message.common.requestTips.success": "操作成功",
    "message.common.requestTips.submitSuccess": "提交成功",
    "message.common.requestTips.getDataFailed": "获取数据发生错误，请重试",
    "message.flowApply.systemFlow": "该流程为系统流程，不支持手动发起",
    "message.flowApply.resultPreview": "结果预览",
    "message.flowApply.attachmentsNotVisible": "暂不支持查看附件",
    "message.flowApply.maxUpload": "最多只能上传{number}个文件",
    "message.flowApply.selected": "当前选中",
    "message.flowApply.personnelSelection": "人员选择",
    "message.flowApply.member": "员工",
    "message.flowApply.officer": "主管",
    "message.flowApply.reporter": "汇报层级",
  "message.flowApply.mutiple_reporter": "连续多级审批至{value}",
    "message.flowApply.role": "角色",
    "message.flowApply.department": "部门",
    "message.flowApply.specialRole": "特殊角色",
    "message.flowApply.memberColumn": "表单字段指定的员工",
    "message.flowApply.officerColumn": "表单字段指定的员工的主管",
    "message.flowApply.reporterColumn": "表单字段指定的员工的汇报层级",
    "message.flowApply.columnPlaceholder": "值为员工 ID",
    "message.flowApply.memberFailed": "员工数据获取失败！",
    "message.flowApply.officerFailed": "主管数据获取失败！",
    "message.flowApply.reporterFailed": "汇报层级数据获取失败！",
    "message.flowApply.roleFailed": "角色数据获取失败！",
    "message.flowApply.departmentFailed": "部门数据获取失败！",
    "message.flowApply.specialRoleFailed": "特殊角色数据获取失败！",
    "message.flowDetail.repeal": "撤回",
    "message.flowDetail.withdrawl": "已撤销",
    "message.flowDetail.print": "打印",
    "message.flowDetail.decode": "解密",
    "message.flowDetail.decoded": "已解密",
    "message.flowDetail.uploadFile": "上传附件",
    "message.flowDetail.uploadTips": "只能传一个附件，请先删除已传附件",
    "message.flowDetail.pass": "通过",
    "message.flowDetail.careOf": "转交",
    "message.flowDetail.turnDown": "驳回",
    "message.flowDetail.collapse": "收起",
    "message.flowDetail.expand": "展开",
    "message.flowDetail.transferee": "转交给:",
    "message.flowDetail.sureTurnDown": "确定要驳回这个申请吗？",
    "message.flowDetail.surePass": "确定要通过这个申请吗？",
    "message.flowDetail.sureRepeal": "确定要撤销这个申请吗？",
    "message.flowDetail.sureDispose": "确定要处理该任务吗？",
    "message.flowDetail.sureDelete": "确定要删除这个申请吗？",
    "message.flowDetail.approveRecord": "审批记录",
    "message.flowDetail.rejected": "已拒绝",
    "message.flowDetail.passed": "已通过",
    "message.flowDetail.pending": "待审批",
    "message.flowDetail.turnedDown": "已驳回",
    "message.flowDetail.draft": "草稿",
    "message.flowDetail.requireTransferee": "请选择转交承接人",
    "message.flowDetail.complete": "已完成",
    "message.flowDetail.Redo": "再次发起",
    "message.flowDetail.openInNewWindow": "在新的窗口中打开",
    "date_picker_Dec": "十二月",
    "date_picker_Nov": "十一月",
    "date_picker_Oct": "十月",
    "date_picker_Sept": "九月",
    "date_picker_Aug": "八月",
    "date_picker_Jul": "七月",
    "date_picker_Jun": "六月",
    "date_picker_May": "五月",
    "date_picker_Apr": "四月",
    "date_picker_Mar": "三月",
    "date_picker_Feb": "二月",
    "date_picker_Jan": "一月",
    "date_picker_Saturday": "六",
    "date_picker_Friday": "五",
    "date_picker_Thursday": "四",
    "date_picker_Wednesday": "三",
    "date_picker_Tuesday": "二",
    "date_picker_Monday": "一",
    "date_picker_Sunday": "日",
    "您确定要删除银行卡:no吗？": "您确定要删除银行卡:no吗？",
    "您确定要删除:start_date至:end_date的工作经历吗？": "您确定要删除:start_date至:end_date的工作经历吗？",
    "您确定要删除:name吗？": "您确定要删除:name吗？",
    "提交成功": "提交成功",
    "Copy link": "复制链接",
    "Link copied": "链接复制成功",
    "Remind": "催办",
    "Click to refresh": "点击刷新",
    "QR code is invalid": "二维码失效",
    "Processing": "处理中",
    "系统异常": "系统异常",
    "已复制好，可贴粘。": "已复制好，可贴粘。",
    "more": "更多",
    "OK": "确认",
    "more_items_with_num": "更多:num项",
    "year_month": ":year年:month月",
    "保存成功": "保存成功",
    "Copy Success": "复制成功",
    "请选择第 :idx 张银行卡的类型": "请选择第 :idx 张银行卡的类型",
    "第 :idx 张银行卡的卡号格式错误": "第 :idx 张银行卡的卡号格式错误",
    "请输入第 :idx 张银行卡的开户行城市": "请输入第 :idx 张银行卡的开户行城市",
    "请选择第 :idx 张银行卡的开户行": "请选择第 :idx 张银行卡的开户行",
    "请输入第 :idx 张银行卡的支行": "请输入第 :idx 张银行卡的支行",
    "请输入第 :idx 张银行卡的预留手机号": "请输入第 :idx 张银行卡的预留手机号",
    "请输入第 :idx 张银行卡的身份证号": "请输入第 :idx 张银行卡的身份证号",
    "请输入第 :idx 张银行卡的姓名": "请输入第 :idx 张银行卡的姓名",
    "请上传第 :idx 张银行卡的正面照片": "请上传第 :idx 张银行卡的正面照片",
    "请上传第 :idx 张银行卡的反面照片": "请上传第 :idx 张银行卡的反面照片",
    "请": "请",
    "第 :idx 份工作经历的证明人电话格式错误": "第 :idx 份工作经历的证明人电话格式错误",
    "第 :idx 个联系人的电话格式错误": "第 :idx 个联系人的电话格式错误",
    "保存草稿成功": "保存草稿成功",
    "提醒": "提醒",
    "提交中，请稍候...": "提交中，请稍候...",
    "页面刷新中...": "页面刷新中...",
    "确认申请归档？": "确认申请归档？",
    "取消": "取消",
    "确认": "确认",
    "确认要删除该字段?": "确认要删除该字段?",
    "自定义浮动项列名不能为空字符串": "自定义浮动项列名不能为空字符串",
    "至少设置一个自定义浮动项列名": "至少设置一个自定义浮动项列名",
    "作废后不可恢复，确认要作废 :txt 吗？": "作废后不可恢复，确认要作废 :txt 吗？",
    "确认删除[:name]记录?": "确认删除[:name]记录?",
    "第 :line 行正在编辑中，请先完成该行的操作": "第 :line 行正在编辑中，请先完成该行的操作",
    "第 :line 行正在删除中，请等待": "第 :line 行正在删除中，请等待",
    "第 :line 行正在保存中，请等待": "第 :line 行正在保存中，请等待",
    "确定删除:name吗?": "确定删除:name吗?",
    "删除确认": "删除确认",
    "确认删除?": "确认删除?",
    "确认保存修改": "确认保存修改",
    "是否删除该费率记录【:id】": "是否删除该费率记录【:id】",
    "第 :line 行数据不全": "第 :line 行数据不全",
    "请选择 :key 的薪资版本": "请选择 :key 的薪资版本",
    "创建": "创建",
    "编辑": "编辑",
    "是否删除该模块": "是否删除该模块",
    "操作成功": "操作成功",
    "保存": "保存",
    "刷新": "刷新",
    "年终奖系数": "年终奖系数",
    "绩效系数": "绩效系数",
    "姓名": "姓名",
    "职级": "职级",
    "员工类型": "员工类型",
    "绩效等级": "绩效等级",
    "有": "有",
    "无": "无",
    "有无年终奖": "有无年终奖",
    "类型": "类型",
    "年终奖": "年终奖",
    "月薪": "月薪",
    "入职时间": "入职时间",
    "司龄系数": "司龄系数",
    "试算年终奖": "试算年终奖",
    "浮动调整": "浮动调整",
    "最终年终奖": "最终年终奖",
    "年总收入": "年总收入",
    "去年年终奖": "去年年终奖",
    "去年总收入": "去年总收入",
    "备注": "备注",
    "全职": "全职",
    "兼职": "兼职",
    "实习": "实习",
    "劳务派遣": "劳务派遣",
    "劳务外包": "劳务外包",
    "退休返聘": "退休返聘",
    "顾问": "顾问",
    "试用": "试用",
    "正式": "正式",
    "待离职审核中": "待离职审核中",
    "待离职已通过": "待离职已通过",
    "Resigned": "已离职",
    "Resigning": "离职中",
    "提交": "提交",
    "网络错误!": "网络错误!",
    "年终奖计算": "年终奖计算",
    "定稿": "定稿",
    "若修改了数据，请先保存再下载数据": "若修改了数据，请先保存再下载数据",
    "数据下载": "数据下载",
    "年终奖总额": "年终奖总额",
    "年终奖公式": "年终奖公式",
    "月薪 * 年终奖系数 * 绩效系数 * 司龄系数 * 有无年终奖 + 月薪 * 司龄系数 * 有无13薪": "月薪 * 年终奖系数 * 绩效系数 * 司龄系数 * 有无年终奖 + 月薪 * 司龄系数 * 有无13薪",
    "员工列表": "员工列表",
    "有无13薪": "有无13薪",
    "VP": "VP",
    "13薪": "13薪",
    "本次绩效": "本次绩效",
    "往期绩效": "往期绩效",
    "共:cnt人": "共:cnt人",
    "保存成功！": "保存成功！",
    "提交中...": "提交中...",
    "绩效定义中:grade的绩效系数必填！": "绩效定义中:grade的绩效系数必填！",
    "业务提成表中:name的绩效等级必选！": "业务提成表中:name的绩效等级必选！",
    "提交成功！": "提交成功！",
    "输入您要生成报表的年月": "输入您要生成报表的年月",
    "审批中": "审批中",
    "网络超时,请重试!": "网络超时,请重试!",
    "验证码发送失败": "验证码发送失败",
    "是": "是",
    "否": "否",
    "下载模板": "下载模板",
    "未知错误": "未知错误",
    "验证码": "验证码",
    "调薪晋升": "调薪晋升",
    "未知状态": "未知状态",
    "导出": "导出",
    "总监": "总监",
    "上级": "上级",
    "职位": "职位",
    "绩效": "绩效",
    "时间": "时间",
    "统计样本为全职员工，上下两条线之间是正常样本，上方的线是最大值，盒子代表中间的 50%，中间的线是中位数，下方的线是最小值。": "统计样本为全职员工，上下两条线之间是正常样本，上方的线是最大值，盒子代表中间的 50%，中间的线是中位数，下方的线是最小值。",
    "总人数": "总人数",
    "推送成功": "推送成功",
    "推送失败": "推送失败",
    "设置生效月份：": "设置生效月份：",
    "生效月份：": "生效月份：",
    "禁止下级修改": "禁止下级修改",
    "上级已锁定": "上级已锁定",
    "按 VP": "按 VP",
    "统计不包含 VP 及以上员工": "统计不包含 VP 及以上员工",
    "日均工时": "日均工时",
    "是否晋升": "是否晋升",
    "晋升记录": "晋升记录",
    "当前薪资": "当前薪资",
    "调薪幅度": "调薪幅度",
    "调薪记录": "调薪记录",
    ":num次": ":num次",
    "共:num人": "共:num人",
    "晋升时间": "晋升时间",
    "原职级": "原职级",
    "调整后": "调整后",
    "调薪时间": "调薪时间",
    "原薪资": "原薪资",
    "备注时间": "备注时间",
    "备注内容": "备注内容",
    "历史绩效": "历史绩效",
    "历史备注记录": "历史备注记录",
    "统计数据": "统计数据",
    "薪资水平": "薪资水平",
    "调薪人数": "调薪人数",
    "调薪人数比例": "调薪人数比例",
    "调薪前薪酬成本": "调薪前薪酬成本",
    "本期调薪总额": "本期调薪总额",
    "调薪后薪酬成本": "调薪后薪酬成本",
    "调薪总额比例": "调薪总额比例",
    "晋升人数": "晋升人数",
    "晋升比例": "晋升比例",
    "数据已锁定": "数据已锁定",
    "数据未锁定": "数据未锁定",
    ":name的薪资": ":name的薪资",
    "未找到以下薪资统计组的数据:": "未找到以下薪资统计组的数据:",
    "该员工没有统计数据": "该员工没有统计数据",
    "没有任何更改": "没有任何更改",
    "所有调薪和晋升计划将在:month自动生效": "所有调薪和晋升计划将在:month自动生效",
    "提交后将无法修改": "提交后将无法修改",
    "确认是否提交？": "确认是否提交？",
    "定稿成功！": "定稿成功！",
    "确定要重新计算该员工薪资吗？": "确定要重新计算该员工薪资吗？",
    "是否禁用租户": "是否禁用租户",
    "是否启用租户": "是否启用租户",
    "是否删除该条目": "是否删除该条目",
    "按 VP 统计": "按 VP 统计",
    ":name的历史绩效": ":name的历史绩效",
    ":name的晋升记录": ":name的晋升记录",
    ":name的调薪记录": ":name的调薪记录",
    ":name的历史备注记录": ":name的历史备注记录",
    ":name的统计数据": ":name的统计数据",
    ":name的薪资水平": ":name的薪资水平",
    "删除": "删除",
    "您的改动会影响本假期的当前发放计划，请谨慎操作。确定修改吗？": "您的改动会影响本假期的当前发放计划，请谨慎操作。确定修改吗？",
    "Tips": "Tips",
    "闲置": "闲置",
    "Idle": "闲置",
    "Received": "已领用",
    "已领用": "已领用",
    "Scrapped": "报废",
    "报废": "报废",
    "维修中": "维修中",
    "In maintenance": "维修中",
    "归还中": "归还中",
    "Returning": "归还中",
    "确定要删除当前配置吗？": "确定要删除当前配置吗？",
    "重新获取": "重新获取",
    "获取验证码": "获取验证码",
    ":time s后可重新获取": ":time s后可重新获取",
    "验证码发送中": "验证码发送中",
    "验证码已发送": "验证码已发送",
    "请求太频繁了哦，请在倒计时结束后再尝试获取": "请求太频繁了哦，请在倒计时结束后再尝试获取",
    "因页面刷新请重新获取验证码再试!": "因页面刷新请重新获取验证码再试!",
    "请输入正确的验证码!": "请输入正确的验证码!",
    "验证码长度错误!": "验证码长度错误!",
    "验证码无效，请重试": "验证码无效，请重试",
    "验证码重试次数过多，请重新获取验证码后再试": "验证码重试次数过多，请重新获取验证码后再试",
    "验证码已过期，请重新获取验证码后再试": "验证码已过期，请重新获取验证码后再试",
    "当前已存在一个解密任务,是否重新获取解密验证码,若是则上一个解密任务可能会失败!": "当前已存在一个解密任务,是否重新获取解密验证码,若是则上一个解密任务可能会失败!",
    "系统申请解密“:scopes”数据，请输入验证码完成授权。": "系统申请解密“:scopes”数据，请输入验证码完成授权。",
    "确定删除该文件？": "确定删除该文件？",
    "处理中...": "处理中...",
    "是否重试该任务？": "是否重试该任务？",
    "是否删除该任务？": "是否删除该任务？",
    "是否清空所有任务？": "是否清空所有任务？",
    "速度：:speed :units ，剩余时间：:resttime s": "速度：:speed :units ，剩余时间：:resttime s",
    "上传已取消": "上传已取消",
    "上传成功！": "上传成功！",
    "上传失败：": "上传失败：",
    "上传失败！": "上传失败！",
    "是否删除该文件": "是否删除该文件",
    "是否删除该消息模板": "是否删除该消息模板",
    "错误：": "错误：",
    "不能为空": "不能为空",
    "描述：": "描述：",
    "验证码无效，请重试！": "验证码无效，请重试！",
    "验证码重试次数过多，请重新获取验证码后再试！": "验证码重试次数过多，请重新获取验证码后再试！",
    "验证码已过期，请重新获取验证码后再试！": "验证码已过期，请重新获取验证码后再试！",
    "当前已存在一个解密任务,请稍后再试！": "当前已存在一个解密任务,请稍后再试！",
    "系统解密中": "系统解密中",
    "是否启用授权应用？": "是否启用授权应用？",
    "是否吊销授权应用？": "是否吊销授权应用？",
    "Are you sure you want to delete the current configuration?": "确定要删除当前配置吗？",
    "Reacquire": "重新获取",
    "Get verification code": "获取验证码",
    "Can be retrieved after :time s": ":time s后可重新获取",
    "Verification code sending": "验证码发送中",
    "Verification code sent": "验证码已发送",
    "The request is too frequent, please try to get it after the countdown is over": "请求太频繁了哦，请在倒计时结束后再尝试获取",
    "Failed to send verification code": "验证码发送失败",
    "Please re-obtain the verification code and try again because the page is refreshed!": "因页面刷新请重新获取验证码再试!",
    "Please enter correct verification code": "请输入正确的验证码!",
    "Verification code length error": "验证码长度错误!",
    "The verification code is invalid, please try again": "验证码无效，请重试",
    "The verification code has been retried too many times, please re-obtain the verification code and try again": "验证码重试次数过多，请重新获取验证码后再试",
    "The verification code has expired, please re-obtain the verification code and try again": "验证码已过期，请重新获取验证码后再试",
    "There is currently a decryption task, whether to reacquire the decryption verification code, if so, the previous decryption task may fail!": "当前已存在一个解密任务,是否重新获取解密验证码,若是则上一个解密任务可能会失败!",
    "Yes": "是",
    "No": "否",
    "The system applies to decrypt “:scopes”, data, please enter the verification code to complete the authorization.": "系统申请解密“:scopes”数据，请输入验证码完成授权。",
    "Verification code": "验证码",
    "Are you sure you want to delete this file?": "确定删除该文件？",
    "Processing...": "处理中...",
    "Download template": "下载模板",
    "System exception": "系统异常",
    "It has been copied and can be pasted.": "已复制好，可贴粘。",
    "Hint": "提示",
    "Do you want to retry the task?": "是否重试该任务？",
    "Do you want to delete this task?": "是否删除该任务？",
    "Do you want to clear all tasks?": "是否清空所有任务？",
    "Speed: :speed :units , remaining time: :resttime s": "速度：:speed :units ，剩余时间：:resttime s",
    "Upload canceled": "上传已取消",
    "Uploaded successfully": "上传成功！",
    "Upload failed:": "上传失败：",
    "Upload failed": "上传失败！",
    "Whether to delete the file": "是否删除该文件",
    "Whether to delete the message template": "是否删除该消息模板",
    "Operate successfully": "操作成功",
    "Error:": "错误：",
    "Unknown error": "未知错误",
    "Can not be empty": "不能为空",
    "Description:": "描述：",
    "The verification code is invalid, please try again.": "验证码无效，请重试！",
    "The verification code has been retried too many times, please re-obtain the verification code and try again.": "验证码重试次数过多，请重新获取验证码后再试！",
    "The verification code has expired, please get the verification code again and try again.": "验证码已过期，请重新获取验证码后再试！",
    "There is currently a decryption task, please try again later.": "当前已存在一个解密任务,请稍后再试！",
    "System decrypting": "系统解密中",
    "Do you want to enable the authorized application?": "是否启用授权应用？",
    "Do you want to revoke the authorized application?": "是否吊销授权应用？",
    "Save": "保存",
    "Name": "名称",
    "Name_XM": "姓名",
    "Type": "类型",
    "Start Date": "开始日期",
    "Entry date": "入职日期",
    "Remark": "备注",
    "Work Site": "工作地",
    "Submit": "提交",
    "Unknown status": "未知状态",
    "Export": "导出",
    "Position": "职位",
    "Time": "时间",
    "Edit": "编辑",
    "Create": "创建",
    "处理中": "处理中",
    "请输入角色名称": "请输入角色名称",
    "选地址": "选地址",
    "时间变更": "时间变更",
    "销除申请": "销除申请",
    "天": "天",
    "元": "元",
    "提交变更申请": "提交变更申请",
    "数据加载中": "数据加载中",
    "变更原因不能为空": "变更原因不能为空",
    "出差未发生变更，无需审批": "出差未发生变更，无需审批",
    "变更原因": "变更原因",
    "开始日期": "开始日期",
    "结束日期": "结束日期",
    "出发地": "出发地",
    "目的地": "目的地",
    "出差时长": "出差时长",
    "超支预算金额": "超支预算金额",
    "出差变更应在行程结束前发起申请，若未按规定时间发起，将会交由VP特批，并记录在案.": "出差变更应在行程结束前发起申请，若未按规定时间发起，将会交由VP特批，并记录在案.",
    "至少要有一段行程": "至少要有一段行程",
    "有日期不合法或不连续，请检查": "有日期不合法或不连续，请检查",
    "第:num段行程有误，请检查": "第:num段行程有误，请检查",
    "页面即将刷新": "页面即将刷新",
    "第:index个餐补": "第:index个餐补",
    "Are you sure you want to delete :name?": "您确定要删除:name吗？",
    "Please enter a role name": "请输入角色名称",
    "确定要删除这个能力吗？": "确定要删除这个能力吗？",
    "是否禁用应用？": "是否禁用应用？",
    "是否启用应用？": "是否启用应用？",
    "确定要执行该操作？": "确定要执行该操作？",
    "请先选择上传文件": "请先选择上传文件",
    "下载任务已取消": "下载任务已取消",
    "点击下载": "点击下载",
    "分配": "分配",
    "可选角色": "可选角色",
    "已分配角色": "已分配角色",
    "File count exceeds the maximum limit of :limit.": "文件个数超过了最大限制数:limit。",
    "请输入能力名称": "请输入能力名称",
    "可用能力": "可用能力",
    "已分配能力": "已分配能力",
    "确定要删除这个角色吗？": "确定要删除这个角色吗？",
    "是否安装应用【:app_key】？": "是否安装应用【:app_key】？",
    "是否卸载应用【:app_key】？": "是否卸载应用【:app_key】？",
    "是否禁用【:app_key】？": "是否禁用【:app_key】？",
    "是否启用【:app_key】？": "是否启用【:app_key】？",
    "是否禁用租户？": "是否禁用租户？",
    "是否启用租户？": "是否启用租户？",
    "确定执行该操作吗": "确定执行该操作吗",
    "Are you sure you want to delete this ability?": "确定要删除这个能力吗？",
    "Do you want to disable the application?": "是否禁用应用？",
    "Do you want to enable the application?": "是否启用应用？",
    "Are you sure you want to take this action?": "确定要执行该操作？",
    "Please select upload file first": "请先选择上传文件",
    "Download task canceled": "下载任务已取消",
    "Click to download": "点击下载",
    "Assign": "分配",
    "Available roles": "可选角色",
    "Assigned roles": "已分配角色",
    "Please enter a ability name": "请输入能力名称",
    "Available abilities": "可用能力",
    "Assigned abilities": "已分配能力",
    "Are you sure you want to delete this role?": "确定要删除这个角色吗？",
    "Do you want to install the application [:app_key]?": "是否安装应用【:app_key】？",
    "Do you want to uninstall the application [:app_key]?": "是否卸载应用【:app_key】？",
    "Do you want to disable [:app_key]?": "是否禁用【:app_key】？",
    "Do you want to enable [:app_key]?": "是否启用【:app_key】？",
    "Do you want to disable the tenant?": "是否禁用租户？",
    "Enable tenant?": "是否启用租户？",
    "Are you sure you want to do this?": "确定执行该操作吗",
    "sync_download_progress": "下载中",
    "sync_download_finished": "下载完成",
    "Network Error!": "网络错误!",
    "Year-end bonus calculation": "年终奖计算",
    "Refresh": "刷新",
    "Finalize": "定稿",
    "If you modify the data, please save it before downloading the data": "若修改了数据，请先保存再下载数据",
    "Data download": "数据下载",
    "Total year-end bonus": "年终奖总额",
    "Year-end bonus formula": "年终奖公式",
    "Monthly salary * year-end bonus factor * performance factor * seniority factor * year-end bonus eligibility + monthly salary * seniority factor * 13 months salary eligibility": "月薪 * 年终奖系数 * 绩效系数 * 司龄系数 * 有无年终奖 + 月薪 * 司龄系数 * 有无13薪",
    "Year-end bonus factor": "年终奖系数",
    "Performance factor": "绩效系数",
    "Employee list": "员工列表",
    "Rank": "职级",
    "Employee Type": "员工类型",
    "Performance level": "绩效等级",
    "13 months salary eligibility": "有无13薪",
    "None": "无",
    "Year-end bonus eligibility": "有无年终奖",
    "13 months salary": "13薪",
    "Annual Bonus": "年终奖",
    "Monthly salary": "月薪",
    "Company working years factor": "司龄系数",
    "This performance": "本次绩效",
    "Past performance": "往期绩效",
    "Trial calculation of year-end bonus": "试算年终奖",
    "Float adjustment": "浮动调整",
    "Final year-end bonus": "最终年终奖",
    "Annual gross income": "年总收入",
    "Last year's year-end bonus": "去年年终奖",
    "Last year's gross income": "去年总收入",
    "Total :cnt people": "共:cnt人",
    "Full-time": "全职",
    "Part-time": "兼职",
    "Intern": "实习",
    "Contractor": "劳务派遣",
    "Outsourcing": "劳务外包",
    "Retirement": "退休返聘",
    "Consultant": "顾问",
    "Probationary": "试用",
    "Regular": "正式",
    "Resignation Approving": "待离职审核中",
    "Resignation Approved": "待离职已通过",
    "Saved successfully!": "保存成功！",
    "Submitting...": "提交中...",
    "In the performance definition: the performance factor of :grade is required!": "绩效定义中:grade的绩效系数必填！",
    "In the business commission table: the performance level of :name is required!": "业务提成表中:name的绩效等级必选！",
    "Submitted successfully!": "提交成功！",
    "Salary and Promotion": "调薪晋升",
    "Set effective month:": "设置生效月份：",
    "Effective month:": "生效月份：",
    "Subordinate modifications are prohibited": "禁止下级修改",
    "superior locked": "上级已锁定",
    "by VP": "按 VP",
    "Statistics by VP": "按 VP 统计",
    "Statistics do not include VP and above employees": "统计不包含 VP 及以上员工",
    "Director": "总监",
    "Leader": "上级",
    "Performance": "绩效",
    "Last three performances": "最近三次绩效",
    "RMB": "人民币",
    "USD": "美元",
    "Average working hours per day": "日均工时",
    "Whether to promote": "是否晋升",
    "Promotion record": "晋升记录",
    "current salary": "当前薪资",
    "salary increase": "调薪幅度",
    "salary adjustment record": "调薪记录",
    ":num times": ":num次",
    "Total :num people": "共:num人",
    "promotion time": "晋升时间",
    "original rank": "原职级",
    "adjusted": "调整后",
    "salary adjustment time": "调薪时间",
    "original salary": "原薪资",
    "Note time": "备注时间",
    "Remarks": "备注内容",
    "The statistical sample is full-time employees, between the upper and lower lines is the normal sample, the upper line is the maximum value, the box represents the middle 50%, the middle line is the median, and the lower line is the minimum value.": "统计样本为全职员工，上下两条线之间是正常样本，上方的线是最大值，盒子代表中间的 50%，中间的线是中位数，下方的线是最小值。",
    "Number of salary adjustments": "调薪人数",
    "Total People": "总人数",
    "Salary adjustment ratio": "调薪人数比例",
    "Salary cost before salary adjustment": "调薪前薪酬成本",
    "Total salary adjustment for this period": "本期调薪总额",
    "Salary cost after salary adjustment": "调薪后薪酬成本",
    "Ratio of Total Salary Adjustment": "调薪总额比例",
    "Number of promotions": "晋升人数",
    "Promotion ratio": "晋升比例",
    "data locked": "数据已锁定",
    "data not locked": "数据未锁定",
    ":name's historical performance": ":name的历史绩效",
    ":name's promotion record": ":name的晋升记录",
    ":name's salary adjustment record": ":name的调薪记录",
    ":name's accident record": ":name近一年的事故记录",
    ":name's history notes": ":name的历史备注记录",
    ":name's statistical data": ":name的统计数据",
    ":name's salary level": ":name的薪资水平",
    ":name's salary": ":name的薪资",
    "No data was found for the following salary statistics groups:": "未找到以下薪资统计组的数据:",
    "This employee has no statistics": "该员工没有统计数据",
    "no changes": "没有任何更改",
    "All salary adjustments and promotion plans will automatically take effect on: month": "所有调薪和晋升计划将在:month自动生效",
    "Cannot be modified after submission": "提交后将无法修改",
    "Are you sure to submit?": "确认是否提交？",
    "Save successfully": "保存成功",
    "Finalized successfully!": "定稿成功！",
    "Submitted successfully": "提交成功",
    "Whether to delete the module": "是否删除该模块",
    "Enter the month and year you want to run the report": "输入您要生成报表的年月",
    "Push successfully": "推送成功",
    "Push failed": "推送失败",
    "Are you sure you want to recalculate this employee's salary?": "确定要重新计算该员工薪资吗？",
    "Whether to disable the tenant": "是否禁用租户",
    "Whether to enable the tenant": "是否启用租户",
    "whether to delete this item": "是否删除该条目",
    "Are you sure you want to delete this field?": "确认要删除该字段?",
    "Custom floating item column name cannot be an empty string": "自定义浮动项列名不能为空字符串",
    "Set at least one custom floating item column name": "至少设置一个自定义浮动项列名",
    "It cannot be restored after being invalidated. Are you sure you want to invalidate :txt?": "作废后不可恢复，确认要作废 :txt 吗？",
    "Are you sure you want to delete [:name] record?": "确认删除[:name]记录?",
    "Line :line is being edited, please complete the operation on this line first": "第 :line 行正在编辑中，请先完成该行的操作",
    "Line :line is being deleted, please wait": "第 :line 行正在删除中，请等待",
    "Line :line is being saved, please wait": "第 :line 行正在保存中，请等待",
    "Delete confirmation": "删除确认",
    "Confirm deletion?": "确认删除?",
    "Confirm to save changes": "确认保存修改",
    "Whether to delete the rate record【:id】": "是否删除该费率记录【:id】",
    "Line :line has incomplete data": "第 :line 行数据不全",
    "Please select the salary version of :key": "请选择 :key 的薪资版本",
    "key.Last_year_end_bonus": "去年年终奖",
    "key.Last_year_gross_income": "去年总收入",
    "Failed to update the message status, please contact the administrator": "跳转目标地址,更新消息状态失败, 请联系管理员",
    "出差事后变更": "出差事后变更",
    "当出差有变动，如提前结束等，可进行事后变更。通过审批后系统会自动更新考勤记录。": "当出差有变动，如提前结束等，可进行事后变更。通过审批后系统会自动更新考勤记录。",
    "删除操作进行中，现在不能保存": "删除操作进行中，现在不能保存",
    "请先填写并保存Key/原文": "请先填写并保存Key/原文",
    "将会删除这个key以及其所有语种的译文，确定删除吗？": "将会删除这个key以及其所有语种的译文，确定删除吗？",
    "数据更新中，暂时不能操作": "数据更新中，暂时不能操作",
    "你有尚未保存的更改，确定关闭页面吗？": "你有尚未保存的更改，确定关闭页面吗？",
    "Key/原文不可为空": "Key/原文不可为空",
    "Key/原文超过了最大长度: 255字符": "Key/原文超过了最大长度: 255字符",
    "The delete operation is in progress and cannot be saved now": "删除操作进行中，现在不能保存",
    "Please fill in and save Key/Original": "请先填写并保存Key/原文",
    "This key and its translations in all languages will be deleted. Are you sure to delete?": "将会删除这个key以及其所有语种的译文，确定删除吗？",
    "The data is being updated, temporarily unable to operate": "数据更新中，暂时不能操作",
    "You have unsaved changes, are you sure you want to close the page?": "你有尚未保存的更改，确定关闭页面吗？",
    "Key/original text cannot be empty": "Key/原文不可为空",
    "Key/original text exceeds the maximum length: 255 characters": "Key/原文超过了最大长度: 255字符",
    "请选择员工": "请选择员工",
    "请填写备注": "请填写备注",
    "Please enter the month": "请输入月份",
    "Remarks cannot be empty": "备注不能为空",
    "月份格式错误": "月份格式错误",
    "Please enter the employee": "请选择员工名单",
    "Please fill in the performance period": "请填写绩效周期",
    "Month format error": "月份格式错误",
    "Revoke Application": "撤销申请",
    "Departure": "出发地",
    "Destination": "目的地",
    "Yuan": "元",
    "Loading": "数据加载中",
    "Delete": "删除",
    "Select Address": "选地址",
    "Business trip change": "出差事后变更",
    "When there is a change in the business trip, such as ending early, etc., it can be changed afterwards. After approval, the system will automatically update the attendance record.": "当出差有变动，如提前结束等，可进行事后变更。通过审批后系统会自动更新考勤记录。",
    "Time Change": "时间变更",
    "start date": "开始日期",
    "end date": "结束日期",
    "Business trip duration": "出差时长",
    "Day(s)": "天",
    "Amount over budget": "超支预算金额",
    "Reason for Change": "变更原因",
    "The application for business trip change should be initiated before the end of the itinerary. If it is not initiated within the specified time, it will be submitted to VP for special approval and recorded.": "出差变更应在行程结束前发起申请，若未按规定时间发起，将会交由VP特批，并记录在案.",
    "Submit a Change Request": "提交变更申请",
    "At least one itinerary": "至少要有一段行程",
    "Change reason cannot be empty": "变更原因不能为空",
    "Dates are illegal or not consecutive, please check": "有日期不合法或不连续，请检查",
    "The itinerary of segment :num is wrong, please check": "第:num段行程有误，请检查",
    "The page will be refreshed soon": "页面即将刷新",
    "No change in business trip, no approval required": "出差未发生变更，无需审批",
    "No. :index Meal Allowance": "第:index个餐补",
    "Your changes will affect the current distribution plan for this holiday, please proceed with caution. Are you sure to modify?": "您的改动会影响本假期的当前发放计划，请谨慎操作。确定修改吗？",
    "失败": "失败",
    "文件不可用": "文件不可用",
    ":必填": ":必填",
    ":item不存在或未启用": ":item不存在或未启用",
    "原租户汇报人工号:number": "原租户汇报人工号:number",
    "File not available": "文件不可用",
    "Failure": "失败",
    ":required": ":必填",
    ":item does not exist or is not enabled": ":item不存在或未启用",
    "Please select the type of card number :idx": "请选择第 :idx 张银行卡的类型",
    "The card number format of the bank card :idx is incorrect": "第 :idx 张银行卡的卡号格式错误",
    "Please enter the bank city of the bank card :idx": "请输入第 :idx 张银行卡的开户行城市",
    "Please select the bank name of the bank card :idx": "请选择第 :idx 张银行卡的开户行",
    "Please enter the branch of the bank card :idx": "请输入第 :idx 张银行卡的支行",
    "Please enter the name of the bank card :idx": "请输入第 :idx 张银行卡的姓名",
    "Please enter the reserved mobile number of the bank card :idx": "请输入第 :idx 张银行卡的预留手机号",
    "Please enter the ID number of the bank card :idx": "请输入第 :idx 张银行卡的身份证号",
    "Please upload the front photo of the bank card :idx": "请上传第 :idx 张银行卡的正面照片",
    "Please upload the backside photo of the bank card :idx": "请上传第 :idx 张银行卡的反面照片",
    "Please": "请",
    "Malformed phone number for contact :idx": "第 :idx 个联系人的电话格式错误",
    "No.: idx work experience prover phone format is wrong": "第 :idx 份工作经历的证明人电话格式错误",
    "The original tenant report to the employee number :number": "原租户汇报人工号:number",
    "Are you sure you want to delete the card :no?": "您确定要删除银行卡:no吗？",
    "Are you sure you want to delete the work experience from :start_date to :end_date?": "您确定要删除:start_date至:end_date的工作经历吗？",
    "Save the draft successfully": "保存草稿成功",
    "Approving": "审批中",
    "Network timed out, please try again!": "网络超时,请重试!",
    "Are you sure you want to delete this authorization?": "确定删除该授权？",
    "Are you sure you want to delete this application? irrecoverable": "您确定要删除此申请吗？不可恢复",
    "Are you sure you want to delete this group?": "确定删除该分组？",
    "Delete Group": "删除分组",
    "Are you sure you want to deactivate this process?": "确定停用该流程？",
    "Are you sure you want to enable this process?": "确定启用该流程？",
    "Are you sure you want to remove this component?": "确定要删除该组件吗？",
    "确认删除该记录?": "确认删除该记录?",
    "没有任何修改": "没有任何修改",
    "值与备注都不可为空": "值与备注都不可为空",
    "重跑节点或任务": "重跑节点或任务",
    "触发【:type】通知事件": "触发【:type】通知事件",
    "备注不可为空!": "备注不可为空!",
    "请输入正确的审批单号！": "请输入正确的审批单号！",
    "Are you sure to delete this record?": "确认删除该记录?",
    "Are you sure to cancel this record?": "确认取消该记录?",
    "No modification": "没有任何修改",
    "Both value and comment cannot be empty": "值与备注都不可为空",
    "Rerun node or task": "重跑节点或任务",
    "Trigger [:type] notification event": "触发【:type】通知事件",
    "Remarks cannot be empty!": "备注不可为空!",
    "Please enter the correct approval number!": "请输入正确的审批单号！",
    "Seal type [:value] already exists": "印章类型【:value】已存在",
    "Creating a seal type [:typeName]": "正在创建印章类型 [:typeName]",
    "Reminded": "提醒",
    "Operate failed": "操作失败",
    "Remind reason":"催办理由",
    "Please specify":"请输入催办理由",
    "Submitting, please wait...": "提交中，请稍候...",
    "Page refreshing...": "页面刷新中...",
    "Are you sure you want to archive contract?": "确认申请归档？",
    "After clicking OK, the contract of :name (:number) will be transferred to :transName and irreversible. <br> Are you sure of the operation?": "点击确定后，将会把 :name(:number) 的合同转交至 :transName 且不可逆。<br>确定操作吗？",
    "面试官": "面试官",
    "手机号格式不正确": "手机号格式不正确",
    "手机号码不能为空": "手机号码不能为空",
    "请选择职级": "请选择职级",
    "人": "人",
    "请选择": "请选择",
    "搜索姓名/工号": "搜索姓名/工号",
    "简历": "简历",
    "HRBP": "HRBP",
    "预览": "预览",
    "下载": "下载",
    "待评价": "待评价",
    "已入职": "已入职",
    "放弃入职": "放弃入职",
    "通过": "通过",
    "取消招聘": "取消招聘",
    "完成招聘": "完成招聘",
    "基本信息": "基本信息",
    "职位名称": "职位名称",
    "职级名称": "职级名称",
    "备注信息": "备注信息",
    "创建时间": "创建时间",
    "请正确填写": "请正确填写",
    "服务器内部错误!": "服务器内部错误!",
    "规格或规格值信息不全,禁止提交!": "规格或规格值信息不全,禁止提交!",
    "归还原因必填": "归还原因必填",
    "提交中": "提交中",
    "当前为状态为：未登录": "当前为状态为：未登录",
    "请联系管理员开启权限": "请联系管理员开启权限",
    "入库数量: 必须大于0": "入库数量: 必须大于0",
    "新建分组": "新建分组",
    "重命名": "重命名",
    "排序": "排序",
    "New Group": "新建分组",
    "Rename": "重命名",
    "Sort": "排序",
    "内推人": "内推人",
    "候选人姓名:": "候选人姓名:",
    "手机号码:": "手机号码:",
    "当前状态:": "当前状态:",
    "请选择当前状态": "请选择当前状态",
    "招聘渠道:": "招聘渠道:",
    "内推人:": "内推人:",
    "内推人必填": "内推人必填",
    "面试流程:": "面试流程:",
    "第:num轮面试:": "第:num轮面试:",
    "面试时间": "面试时间",
    "选择日期时间": "选择日期时间",
    "添加面试流程": "添加面试流程",
    "会议室:": "会议室:",
    "请填写线下会议室地址或者腾讯会议室ID": "请填写线下会议室地址或者腾讯会议室ID",
    "上传简历:": "上传简历:",
    "点击上传": "点击上传",
    "一": "一",
    "二": "二",
    "三": "三",
    "四": "四",
    "五": "五",
    "六": "六",
    "七": "七",
    "八": "八",
    "九": "九",
    "十": "十",
    "最多添加10轮面试": "最多添加10轮面试",
    "操作失败，请重试！": "操作失败，请重试！",
    "招聘部门:": "招聘部门:",
    "当前部门总人数:total人（包含实习生:num人）": "当前部门总人数:total人（包含实习生:num人）",
    "职位名称:": "职位名称:",
    "请选择职位名称": "请选择职位名称",
    "职级名称:": "职级名称:",
    "招聘人数:": "招聘人数:",
    "员工类型:": "员工类型:",
    "请选择员工类型": "请选择员工类型",
    "招聘原因:": "招聘原因:",
    "请选择招聘原因": "请选择招聘原因",
    "优先级:": "优先级:",
    "请选择优先级": "请选择优先级",
    "期望面试日期:": "期望面试日期:",
    "key.expected.date.interview": "示例：\n面试官1：每周四下午\n面试官2：每天上午11：00~12：00；\n面试官3：每周二三四下午3:00~6:00",
    "直属上级": "直属上级",
    "请选择直属上级": "请选择直属上级",
    "面试流程": "面试流程",
    "第:num轮面试": "第:num轮面试",
    "请填写": "请填写",
    "岗位职责:": "岗位职责:",
    "请先思考职位的绩效目标，然后思考为了完成绩效目标，该员工需要做哪些工作": "请先思考职位的绩效目标，然后思考为了完成绩效目标，该员工需要做哪些工作",
    "任职要求:": "任职要求:",
    "请按以下纬度填写: 基本要求（学历、专业、经验）、能力要求、性格特质、工作经历（如有）": "请按以下纬度填写: 基本要求（学历、专业、经验）、能力要求、性格特质、工作经历（如有）",
    "备注:": "备注:",
    "招聘部门必选": "招聘部门必选",
    "职位名称必选": "职位名称必选",
    "招聘人数必填": "招聘人数必填",
    "招聘人数至少为一人": "招聘人数至少为一人",
    "招聘人数为正整数": "招聘人数为正整数",
    "员工类型必选": "员工类型必选",
    "招聘原因必选": "招聘原因必选",
    "招聘优先级必选": "招聘优先级必选",
    "第:num轮面试官必选！": "第:num轮面试官必选！",
    "岗位职责必填": "岗位职责必填",
    "任职要求必填": "任职要求必填",
    "招聘创建时间": "招聘创建时间",
    "上传简历日期": "上传简历日期",
    "简历渠道": "简历渠道",
    "会议室": "会议室",
    "未通过": "未通过",
    "评价时间": "评价时间",
    "前往评价": "前往评价",
    "已通过": "已通过",
    "面试时间：:datetime": "面试时间：:datetime",
    "评价时间：:datetime": "评价时间：:datetime",
    "待筛选": "待筛选",
    "筛选不通过": "筛选不通过",
    "待邀约面试": "待邀约面试",
    "待面试": "待面试",
    "面试中": "面试中",
    "面试未通过": "面试未通过",
    "面试通过": "面试通过",
    "录用审批中": "录用审批中",
    "已发Offer": "已发Offer",
    "放弃面试": "放弃面试",
    "不好": "不好",
    "可提升": "可提升",
    "一般": "一般",
    "良好": "良好",
    "优秀": "优秀",
    "面试结果必选": "面试结果必选",
    "面试评价必填": "面试评价必填",
    "Interview round :num": "第:num轮面试",
    "面试结果为:status，确认提交？": "面试结果为:status，确认提交？",
    "结果确认": "结果确认",
    ":name已于:date被:hr通过:channel添加至人才库,继续添加将在原来基础上增加一份简历，不影响历史信息。": ":name已于:date被:hr通过:channel添加至人才库,继续添加将在原来基础上增加一份简历，不影响历史信息。",
    "第:num面": "第:num面",
    "未找到该手机号的面试预约，请确认手机号，或手动填写签到信息": "未找到该手机号的面试预约，请确认手机号，或手动填写签到信息",
    "Referrer": "内推人",
    "Candidate Name:": "候选人姓名:",
    "Phone number:": "手机号码:",
    "Current status:": "当前状态:",
    "Please select current status": "请选择当前状态",
    "Recruitment channels:": "招聘渠道:",
    "Referrer:": "内推人:",
    "Referrer is required": "内推人必填",
    "Interview process:": "面试流程:",
    "Interview round :num:": "第:num轮面试:",
    "Interviewer": "面试官",
    "Interview time": "面试时间",
    "Select date time": "选择日期时间",
    "Add interview process": "添加面试流程",
    "Meeting room:": "会议室:",
    "Please fill in the offline meeting room address or Tencent meeting room ID": "请填写线下会议室地址或者腾讯会议室ID",
    "Upload resume:": "上传简历:",
    "Click to upload": "点击上传",
    "Phone number format is incorrect": "手机号格式不正确",
    "Mobile can not be blank": "手机号码不能为空",
    "one": "一",
    "two": "二",
    "three": "三",
    "four": "四",
    "five": "五",
    "six": "六",
    "seven": "七",
    "eight": "八",
    "nine": "九",
    "ten": "十",
    "Add up to 10 rounds of interviews": "最多添加10轮面试",
    "Operation failed, please try again!": "操作失败，请重试！",
    "Recruiting department:": "招聘部门:",
    "The total number of people in the current department is :total (including interns :num people)": "当前部门总人数:total人（包含实习生:num人）",
    "Job title:": "职位名称:",
    "Please select a job title": "请选择职位名称",
    "Please select a job base": "请选择Base",
    "Rank title:": "职级名称:",
    "Please select a rank": "请选择职级",
    "Number of recruits:": "招聘人数:",
    "People": "人",
    "Employee type:": "员工类型:",
    "Please select employee type": "请选择员工类型",
    "Recruitment reason:": "招聘原因:",
    "Please select the reason for recruitment": "请选择招聘原因",
    "Priority:": "优先级:",
    "Please select a priority": "请选择优先级",
    "Expected interview date:": "期望面试日期:",
    "Immediate superior": "直属上级",
    "Please select the immediate superior": "请选择直属上级",
    "Interview process": "面试流程",
    "Please choose": "请选择",
    "Please fill out": "请填写",
    "Job Responsibilities:": "岗位职责:",
    "Think about the performance goals of the position first, then think about what the employee needs to do to accomplish the performance goals": "请先思考职位的绩效目标，然后思考为了完成绩效目标，该员工需要做哪些工作",
    "Job requirements:": "任职要求:",
    "Please fill in the following latitudes: basic requirements (education, major, experience), ability requirements, personality traits, work experience (if any)": "请按以下纬度填写: 基本要求（学历、专业、经验）、能力要求、性格特质、工作经历（如有）",
    "Remark:": "备注:",
    "Please fill in the remarks": "请填写备注",
    "Recruitment department is required": "招聘部门必选",
    "Job title is required": "职位名称必选",
    "The number of recruits is required": "招聘人数必填",
    "The number of recruits is at least one person": "招聘人数至少为一人",
    "The number of recruits is a positive integer": "招聘人数为正整数",
    "Employee type is required": "员工类型必选",
    "The reason for recruitment is required": "招聘原因必选",
    "Recruitment priority is required": "招聘优先级必选",
    "No. :num round of interviewers must be selected!": "第:num轮面试官必选！",
    "Job responsibilities are required": "岗位职责必填",
    "Job requirements are required": "任职要求必填",
    "Search name/employee number": "搜索姓名/工号",
    "Recruitment create time": "招聘创建时间",
    "CV": "简历",
    "Resume upload date": "上传简历日期",
    "Resume channel": "简历渠道",
    "preview": "预览",
    "Preview": "预览",
    "Download": "下载",
    "Meeting room": "会议室",
    "Candidate.Status.Failed": "未通过",
    "Candidate.Status.Passed": "通过",
    "Candidate.Status.Pending": "待定",
    "Evaluation time": "评价时间",
    "Go to evaluate": "前往评价",
    "To be Evaluated": "待评价",
    "Interview time: :datetime": "面试时间：:datetime",
    "Evaluation time: :datetime": "评价时间：:datetime",
    "Resume screening": "待筛选",
    "Resume screening failed": "筛选不通过",
    "Interview invitation": "待邀约面试",
    "Waiting for interview": "待面试",
    "In the interview": "面试中",
    "Interview failed": "面试未通过",
    "Interview passed": "面试通过",
    "Offer approving": "录用审批中",
    "Offered": "已发Offer",
    "Give up interview": "放弃面试",
    "Onboarded": "已入职",
    "Give Up Onboarding": "放弃入职",
    "Not good": "不好",
    "Can be improved": "可提升",
    "General": "一般",
    "Good": "良好",
    "Excellent": "优秀",
    "Interview results are required": "面试结果必选",
    "Interview evaluation is required": "面试评价必填",
    "The interview result is :status, confirm submission?": "面试结果为:status，确认提交？",
    "Approve": "通过",
    "Result Confirmation": "结果确认",
    ":name has been added to the talent pool by :hr via :channel on :date, and continuing to add a resume will add a resume on the original basis without affecting historical information.": ":name已于:date被:hr通过:channel添加至人才库,继续添加将在原来基础上增加一份简历，不影响历史信息。",
    "No interview appointment with this phone number was found, please confirm the phone number, or manually fill in the check-in information": "未找到该手机号的面试预约，请确认手机号，或手动填写签到信息",
    "面试结果:": "面试结果:",
    "面试评价:": "面试评价:",
    "输入面试评价": "输入面试评价",
    "面试评价记录": "面试评价记录",
    "(已通过)": "(已通过)",
    "(已拒绝)": "(已拒绝)",
    "查看评价详情": "查看评价详情",
    "评价详情": "评价详情",
    "Interview result:": "面试结果:",
    "Interview evaluation:": "面试评价:",
    "Enter interview evaluation": "输入面试评价",
    "Interview Evaluation Record": "面试评价记录",
    "(Passed)": "(已通过)",
    "(Rejected)": "(已拒绝)",
    "(Pending)": "(待定)",
    "View review details": "查看评价详情",
    "Evaluation Details": "评价详情",
    "备注不能为空": "备注不能为空",
    "您确认要:name [:email] 吗？": "您确认要:name [:email] 吗？",
    "ap必填": "ap必填",
    "ap地址格式不正确！": "ap地址格式不正确！",
    "配置名称必填": "配置名称必填",
    "配置信息不是json格式": "配置信息不是json格式",
    "请重试": "请重试",
    "mac地址必填": "mac地址必填",
    "mac地址不正确！": "mac地址不正确！",
    "Are you sure you want :name [:email]?": "您确认要:name [:email] 吗？",
    "AP required": "ap必填",
    "AP address format is incorrect!": "ap地址格式不正确！",
    "Configuration name is required": "配置名称必填",
    "Configuration information is not in json format": "配置信息不是json格式",
    "Please try again": "请重试",
    "Mac address is required": "mac地址必填",
    "The mac address is incorrect!": "mac地址不正确！",
    "Internal server error!": "服务器内部错误!",
    "The specification or specification value information is incomplete, and the submission is prohibited!": "规格或规格值信息不全,禁止提交!",
    "Return reason is required": "归还原因必填",
    "Please fill in correctly": "请正确填写",
    "Submitting": "提交中",
    "The current status is: not logged in": "当前为状态为：未登录",
    "Please contact the administrator to enable permissions": "请联系管理员开启权限",
    "Storage quantity must be greater than 0": "入库数量: 必须大于0",
    "Please set the scheduling execution time": "请设置计划执行时间",
    "Upload": "上传",
    "No data": "暂无数据",
    "or meet": "或满足",
    "and meet": "且",
    "when meet": "当",
    "Condition group": "条件组",
    "Add option": "添加选项",
    "Add condition": "添加条件",
    "Add condition group": "添加条件组",
    "equal to": "等于",
    "not equal to": "不等于",
    "greater than": "大于",
    "greater than or equal to": "大于等于",
    "less than": "小于",
    "less than or equal to": "小于等于",
    "belong to": "属于",
    "not belong to": "不属于",
    "There is an unknown form field in conditional expression.": "条件表达式中有一个未知的表单字段",
    "Some of the formulas which have been edited can not be parsed by the Basic editor.": "基本编辑器无法解析当前某些条件公式",
    "There is no form field for condition setting.": "没有可用的表单字段用于条件设置",
    "Sun.": "周日",
    "Mon.": "周一",
    "Wed.": "周三",
    "Fri.": "周五",
    "Sat.": "周六",
    "Jan.": "一月",
    "Feb.": "二月",
    "Mar.": "三月",
    "Apr.": "四月",
    "May.": "五月",
    "Jun.": "六月",
    "Jul.": "七月",
    "Aug.": "八月",
    "Sep.": "九月",
    "Oct.": "十月",
    "Nov.": "十一月",
    "Dec.": "十二月",
    "The earliest effective month for this statement is :month": "该报表最早生效月份是:month",
    "Select Approver": "请选择审批人",
    "Special subsidies and policies": "特殊补贴和政策",
    "Accident Record": "事故记录",
    "accident.event_date": "发生日期",
    "accident.title": "事故标题",
    "accident.summary": "事故概要",
    "accident.level": "事故等级",
    "accident.event_type": "责任类型",
    "accident.penalty": "处罚",
    "Details": "详情",
    "operate": "操作",
    "check submit has accident record": "以下员工近一年有事故记录：<br>\n :list <br>\n 请参阅《:link》，决定是否要给予调薪晋升。",
    "Incident Rating and Handling Policy": "快牛智能集团事故评级及处理制度",
    "Job Base": "Base",
    "Job Base:": "Base:",
    "Are you sure you want to delete the report [:txt]": "您确认要删除报表【:txt】吗？",
    "Do you want to clear the benefit content of :text": "是否要清空:text的福利内容？",
    "Please select the HRBP": "请选择HRBP",
    "小学": "小学",
    "初中": "初中",
    "高中": "高中",
    "中专": "中专",
    "大专": "大专",
    "本科": "本科",
    "硕士研究生": "硕士研究生",
    "博士研究生": "博士研究生",
    "其他": "其他",
    "学士": "学士",
    "硕士": "硕士",
    "博士": "博士",
    "居民身份证": "居民身份证",
    "港澳居民来往内地通行证": "港澳居民来往内地通行证",
    "台湾居民来往大陆通行证": "台湾居民来往大陆通行证",
    "外国护照": "外国护照",
    "Please enter the reason for the urging": "请输入催办原因"
}
