import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'
import Events from '@platform_resources/js/components/performance-measure/events'

Vue.use(Router)

const moduleName = process.env.MIX_VUE_APP_NAME

const routers = new Router({
  mode: 'history',
  routes: [
    {
      path: `/${moduleName}/formDesign`,
      name: 'FormDesign',
      component: () => import('../page/FormDesign.vue'),
    },
    {
      path: `/forms/formIndex`,
      name: 'PlatformFormDesign',
      component: () => import('../page/FormDesign.vue'),
    },
    {
      path: `/${moduleName}/flowTemplateDesign`,
      name: 'FlowTemplateDesign',
      component: () => import('../page/FlowDesign.vue'),
    },
    {
      path: `/${moduleName}/flowDetail`,
      name: 'FlowDetail',
      component: () => import('../page/FlowDetailPage.vue'),
      beforeEnter: (to, from, next) => {
        store.dispatch('getFlowDetailById', parseInt(to.query.flowId))
        next()
      },
    },
    {
      path: `/${moduleName}/flowPrint`,
      name: 'FlowPrint',
      component: () => import('../page/FlowPrintPage.vue'),
    },
    {
      path: `/${moduleName}/flowDesign`,
      name: 'FlowDesign',
      component: () => import('../page/FlowDesign.vue'),
    },
    {
      path: `/${moduleName}/flowApply`,
      name: 'FlowApply',
      component: () => import('../page/FlowApply.vue'),
      beforeEnter: (to, from, next) => {
        // 请求流程设计数据
        store.dispatch(
          'getFlowDefineById',
          parseInt(window.flowId !== undefined ? window.flowId : to.query.flowId)
        )
        // 若有旧数据时，请求旧表单数据
        if (to.query.entryId) {
          store.dispatch('getOldData', parseInt(to.query.entryId))
        }
        next()
      },
    },
    {
      path: `/${moduleName}/tableEdit`,
      name: 'TableEdit',
      component: () => import('../page/TableEdit.vue'),
    },
    {
      path: `/${moduleName}/customData`,
      name: 'CustomData',
      component: () => import('../page/CustomData.vue'),
    },
    {
      path: '/',
      redirect: `/${moduleName}/flowDesign`,
    },
  ],
})

routers.beforeEach((to, from, next) => {
  Vue.EventBus.emit(Events.LocationChanged, {location: to})
  next()
})

export default routers
