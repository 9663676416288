<template>
  <div></div>
</template>

<script>
import Vue from 'vue'
import Events from '../events'

export default {
  data() {
    return {
      feedInterval: null,
      indicators: []
    }
  },
  created() {
    Vue.EventBus.on(Events.LocationChanged, () => {
      this.feedIndicator()
      window.traceId = window.generateRandomString(10)
      window.stage = 'initial'

      // 路由变更后，800ms以内的发出的Ajax请求视为首屏数据加载
      setTimeout(() => {
        window.stage = 'loaded'
      }, 800)
    })

    Vue.EventBus.on(Events.RequestFinished, (params) => {
      const networkCost = Math.round((params.responseTimestamp - params.requestTimestamp - params.requestSpentTime) / 2)
      const beRequestTime = parseInt(params.requestTimestamp) + networkCost
      const beResponseTime = beRequestTime + Number(params.requestSpentTime)

      if (params.pageStage === 'initial') {
        const indicator = {
          tenant: window.location.hostname,
          trace_id: params.traceId,
          ua: navigator.userAgent,
          device: null,
          page_uri: params.location,
          page_state: params.pageStage,
          page_version: 'v2',
          event_code: params.apiUri,
          event_type: 'page_data_loading',
          event_desc: '',
          event_location: null,
          fe_start_time: params.requestTimestamp,
          be_request_time: beRequestTime,
          be_response_time: beResponseTime,
          fe_finish_time: params.responseTimestamp
        }
        this.indicators.push(indicator)
      }
    })

    window.addEventListener('beforeunload', this.feedIndicator);

    this.feedInterval = setInterval(this.feedIndicator, 3000)

    Vue.EventBus.emit(Events.LocationChanged, {location: window.location.pathname})
  },
  methods: {
    feedIndicator() {
      if (this.indicators.length > 0) {
        window.report(this.indicators)
        this.clearIndicators()
      }
    },
    clearIndicators() {
      this.indicators = []
    }
  },
  beforeDestroy() {
    this.feedIndicator();
    Vue.EventBus.off(Events.LocationChanged)
    Vue.EventBus.off(Events.RequestFinished)
    clearInterval(this.feedInterval)
    window.removeEventListener('beforeunload', this.feedIndicator)
  }
}
</script>

